import React from 'react';
import Slideshow from '../../components/slideshow';

const Recents = () => {
  return (
    <div className="slideshow-container">
      <Slideshow />
    </div>
  );
};

export default Recents;
