import React, { useState, useEffect } from 'react';
import firebase from "gatsby-plugin-firebase";

const Slideshow = () => {
  const [gifs, setGifs] = useState([]);

  useEffect(() => {

    // Hack for raspberry pi chromium scroll bars?
    if (document) document.body.style.overflow = 'hidden';
    if (document) document.body.style['scrollbar-width'] = 'none';

    let numGifs = 1;
    let classCode = '';

    const params = new URLSearchParams(window.location.search);

    if (params.has('code')) classCode = params.get('code');
    if (params.has('count')) numGifs = parseInt(params.get('count'));

    const db = firebase.firestore();

    let query = db.collection('uploads');

    // If class code is set, filter by class code
    if (classCode && classCode !== '') {
      query = query.where('class_code', '==', classCode);
    }

    query = query.limit(numGifs).orderBy('upload_time', 'desc');
    
    const unsubscribe = query.onSnapshot(snapshot => {
        const newGifs = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log(newGifs);
        setGifs(newGifs);
      });
    return unsubscribe;
  }, []);
  
  return (
    <div className="slideshow">
      {gifs.map(gif => (
        <div>
          { gif.geo && (
            <span key={gif.id} className="geo-label">{gif.geo}</span>
          )}
          <img key={gif.id} src={gif.file_url} alt="" />
        </div>
      ))}
    </div>
  );

};

export default Slideshow;
